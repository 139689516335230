.main {
  background-color: gray;
  width: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.container {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  background-image: url('./images/background.svg');
  background-size: cover;
  background-repeat: no-repeat;
  aspect-ratio: 9 / 16;
  position: relative;
  overflow: hidden;
}