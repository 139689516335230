.languagesList {
  list-style: none;
  display: flex;
  height: 4vh;
  margin: 0;
  padding: 5%;
}

.languagesList li,
.languagesList span {
  font-size: 40px;
  font-size: 4vh;
  line-height: 50px;
  line-height: 4.5vh;
}

.languageElement {
  cursor: pointer;
  text-transform: uppercase;
}

.currentLanguage {
  color: var(--secondary);
}