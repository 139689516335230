.wrapper {
  --transition-time: 0.25s;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .topElements {
    height: 6%;
    position: relative;
    z-index: 1;

    .timerWrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 15%;

      transition: opacity var(--transition-time) ease-out;

      &.timerHidden {
        opacity: 0;
      }

      .timer {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primary);
        color: var(--background);
  
        p {
          font-size: 100px;
          font-size: 10vh;
          padding: 20% 0;
        }
      }
    }
  }

  .bottomElements {
    height: 30vh;

    .squirrelWrapper {
      position: relative;
      height: 70%;
      bottom: -16%;

      .squirrel {
        position: absolute;
        bottom: 33%;
        left: 50%;
        transform: translateX(-50%);
        transition: opacity 0.3s ease-in-out;

        &.invisible {
          opacity: 0;
        }
      }
    }
    
    .arrows {
      --arrow-margin: 7%;
      display: flex;
      justify-content: center;
      height: 30%;
      padding-bottom: 5%;
      position: relative;
      z-index: 1;

      .arrowLeft {
        margin-right: var(--arrow-margin);
      }
      
      .arrowRright {
        margin-left: var(--arrow-margin);
      }
    }
  }

  .fallingObjectsWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    overflow-y: hidden;
    opacity: 0;

    &.visible {
      opacity: 1;
      height: 87vh;
    }

    .fallingObjectsInner {
      position: absolute;
      height: 15vh;
      bottom: -24vh;
      left: 0;
      width: 100%;
      display: flex;
      align-items: flex-end;

      .fallingObjects {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        flex-shrink: 0;
        height: 100vh;
        bottom: 0;
        width: 100%;
        margin-bottom: -30vh;
        transform: translateY(-140vh);
      }
    }
  }
}