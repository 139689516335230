@font-face {
  font-family: 'Frutiger';
  src:  url('./fonts/NeueFrutigerWorld-Regular.woff2') format('woff2'),
        url('./fonts/NeueFrutigerWorld-Regular.woff') format('woff'),
        url('./fonts/NeueFrutigerWorld-Regular.eot') format('eot');
}

@font-face {
  font-family: 'FrutigerBold';
  src:  url('./fonts/NeueFrutigerWorld-Heavy.woff2') format('woff2'),
        url('./fonts/NeueFrutigerWorld-Heavy.woff') format('woff'),
        url('./fonts/NeueFrutigerWorld-Heavy.eot') format('eot');
}

:root {
  --primary: #ffffff;
  --secondary: #A78226;
  --background: #00625D;
  --yellow: #FFD500;
  --error-color: #CA1A1A;
}

html {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

body {
  margin: 0;
  font-family: 'Frutiger', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary);

  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

strong {
  font-family: "FrutigerBold", sans-serif;
}

h1, h2, h3, h4 {
  font-family: "FrutigerBold", sans-serif;
}

.big-text {
  font-size: 51px;
  font-size: 5.7vh;
  line-height: 66px;
  line-height: 7.05vh;
}

.capitalize {
  text-transform: capitalize;
}