.wrapper {
  .rankingList {
    margin-top: 7%;
    list-style: none;
    padding: 0 5%;
    li {
      &.firstRow {
        margin-bottom: 2.5vh;
        p {
          font-family: "FrutigerBold", sans-serif;
        }
      }
      &.currentLeadRow {
        p {
          color: var(--yellow);
        }
      }
      display: flex;
      justify-content: space-between;
      margin: 1.8vh 0;
      p {
        
        
        &.first {
          width: 25%;
          padding-left: 0;
        }
        &.second {
          width: 50%;
          word-wrap: break-word;
          overflow-wrap: break-word;
          -webkit-hyphens: auto;
          -moz-hyphens: auto;
          hyphens: auto;
        }
        &.third {
          width: 25%;
          padding-right: 0;
        }
        font-size: 3vh;
        text-align: left;
        padding: 0 5%;
      }
    }
  }
}