.wrapper {
  height: 100%;
  position: relative;

  .languageSwitcherWrapper {
    width: 100%;
  }
  
  .logosWrapper {
    max-width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20%;
    margin-left: auto;
    margin-right: auto;

    .wandergameWrapper {
      width: 100%;
      margin-top: 5%;
    }

    img {
      width: 100%;
    }
  }
  
  .bottomElements {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding-bottom: 18%;

    .squirrelWrapper {
      padding-left: 10%;
    }
    
    .squirrel {
      width: 30%;
    }

    .shieldWrapper {
      position: absolute;
      top: 30%;
      right: 50%;
    }
  }

  .marginBottom {
    margin-bottom: 3%;
  }
}