.wrapper {
  height: 100%;

  .infoBlock {
    padding-top: 5%;
    padding-bottom: 7%;
  }

  .simpleText {
    font-size: 28px;
    font-size: 3.2vh;
    line-height: 38px;
    line-height: 4.0vh;

    @media screen and (max-width: 640px) {
      font-size: 24px;
      font-size: 2.8vh;
      line-height: 34px;
      line-height: 3.5vh;
    }
  }
  .squirrelWrapper {
    display: flex;
    justify-content: center;
    margin: 10% 0;

    .squirrel {
      max-height: 27vh;
    }
  }

  .shields {    
    transition: opacity 0.4s ease;

    &.inVisible {
      opacity: 0;
      height: 0;
      width: 0;
      overflow: hidden;
    }
  }
}