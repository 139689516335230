.foCell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5% 0;

  img {
    width: 100%;
    max-width: 70%;
  }
}
