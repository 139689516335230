.wrapper {
  height: 100%;

  .descriptionGame {
    display: flex;
    flex-direction: column;
    align-items: center;

    .descriptionGameTitle {
      margin-bottom: 3%;
      text-transform: uppercase;
      text-align: center;
    }
    
    .descriptionGame p {
      text-align: center;
    }
    
    .marginBottom {
      margin-bottom: 3%;
    }
    
    .gameDescriptionImage {
      width: 65%;
      margin: 2% 0;
      min-height: 25%;
    }

    .bottomTextBlock {
      padding-top: 3%;
    }
  }

  .shieldsWrapper {
    bottom: -9%;
  }
  
}