.squirrelWidth {
  /* width: 25%; */
}

svg {
  max-height: 100%;
}

.blinking {
  animation: blinker 1s ease infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.25;
  }
}