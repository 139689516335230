
.wrapper {
  --text-padding: 18%;
  position: absolute;
  width: 45%;
  cursor: pointer;

  &.wrapperLeft {
    left: 49%;
    transform: translateX(-100%);
    top: 0;
  }
  
  &.wrapperRight {
    right: 49%;
    transform: translateX(100%);
    top: 0;
  }
  
  .text {
    position: absolute;
    top: 9%;
    left: 0;
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
    font-family: 'FrutigerBold', sans-serif;
    color: #000000;
    font-size: 22px;
    font-size: 2.4vh;
    line-height: 28px;
    line-height: 3.2vh;

    p {
      text-align: center;
    }
  }
}