.wrapper {
  --border-width: 0.2vw;
  padding: 3% 8%;
  padding-top: 1%;

  .error {
    input {
      border: var(--border-width) solid var(--error-color);
    }
  }

  .errorMessage {
    p {
      color: #ffffff;
      text-align: left;
    }
  }

  fieldset {
    margin: 4% 0;
    border: none;
    padding: 0;

    .termsLabel {
      display: flex;
      align-items: center;

      p {
        text-decoration: underline;
      }

      input {
        height: 3vh;
        width: 3vh;
        margin-right: 3%;
        margin-left: 0;
      }
    }
  }

  input[type=text],
  input[type=email] {
    --input-text-color: #014944;
    --input-text-size-vh: 2.1vh;
    --input-text-size-px: 22px;
    width: 100%;
    padding: 0 5%;
    height: 7vh;
    color: var(--input-text-color);
    font-size: var(--input-text-size-px);
    font-size: var(--input-text-size-vh);

    &::placeholder  /* Chrome, Firefox, Opera, Safari 10.1+ */ {
      color: var(--input-text-color);
      opacity: 1; /* Firefox */
      font-size: var(--input-text-size-px);
      font-size: var(--input-text-size-vh);
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: var(--input-text-color);
      font-size: var(--input-text-size-px);
      font-size: var(--input-text-size-vh);
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: var(--input-text-color);
      font-size: var(--input-text-size-px);
      font-size: var(--input-text-size-vh);
    }
  }

  .genderWrapper {
    display: flex;
    justify-content: space-between;

    .radio {
      width: 31%;
      label {
        display: flex;
        align-items: center;

        .radioInputWrapper {
          width: 35%;
          margin-right: 5%;

          input {
            width: 4vh;
            height: 4vh;
          }
        }
    
        .radioTextWrapper {
          width: 60%;

          p {
            padding-top: 2%;
          }
        }
      }
    }
  }

  .message {
    display: none;
    margin-top: 8%;
    margin-bottom: 3%;
    &.messageShown {
      display: block;
      p {
        text-align: left;
      }
    }
  }

  .submitButtonWrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 5%;

    button {
      padding: 3% 9%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.2vh;
      cursor: pointer;
      text-transform: capitalize;
    }
  }

  /* Radio button and Checkbox .required needs an after to show */
  input[type=radio].required::after, input[type=checkbox].required::after {
    display: block;
    width: 100%;
    height: 100%;
    background: transparent;
    content: '';
    border:  var(--border-width) solid var(--error-color) !important;
    box-sizing: border-box;
  }
  /* Radio buttons are round, so add 100% border radius. */
  input[type=radio].required::after {
    border-radius:100%;
  }

}

.nameText {
  text-transform: capitalize;
}