  
  /* width */
  ::-webkit-scrollbar {
    width: 2vh;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ffffff; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  }
  

.wrapper {
  background-color: var(--background);
  color: var(--primary);
  max-width: 95%;
  max-height: 80vh;
  min-height: 20vh;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5%;
  padding: 5%;
  overflow-y: auto;
  overflow-x: hidden;

  h2, p {
    text-align: center;
  }

  h2 {
    font-size: 34px;
    font-size: 3.8vh;
    line-height: 44px;
    line-height: 4.7vh;
    text-transform: uppercase;
    font-family: "FrutigerBold", sans-serif;
    margin-bottom: 3%;

    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }

  h3 {
    font-size: 30px;
    font-size: 3.5vh;
    line-height: 40px;
    line-height: 4.5vh;
    font-family: "FrutigerBold", sans-serif;
    margin-bottom: 3%;

    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }

  p {
    font-size: 20px;
    font-size: 2.1vh;
    line-height: 25px;
    line-height: 3.3vh;
  }

  @media screen and (max-width: 640px) {
    max-height: 70vh;

    h2 {
      font-size: 30px;
      font-size: 3.4vh;
      line-height: 40px;
      line-height: 4.3vh;
      margin-bottom: 3%;
    }

    h3 {
      font-size: 26px;
      font-size: 3.0vh;
      line-height: 36px;
      line-height: 3.9vh;
    }

    p {
      font-size: 18px;
      font-size: 1.9vh;
      line-height: 24px;
      line-height: 2.7vh;
    }
  }
}