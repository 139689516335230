.wrapper {
  --padding: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  background-color: var(--background);
  transition: opacity var(--transition-time) ease-out;

  &.topBarHidden {
    opacity: 0;
  }

  .livesBlock {
    height: 50%;
    display: flex;
    padding-left: var(--padding);
    width: 30%;

    img {
      max-height: 100%;
      padding: 0 4%;
      width: 33%;
    }
  }

  .scoreBlock {
    padding-right: var(--padding);

    p {
      font-size: 50px;
      font-size: 3vh;
    }
  }
}